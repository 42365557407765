<template>
  <div id="settings">

  </div>
</template>

<script>
    // Libraries
    //const moment = require("moment");

    // Vue Components
    //import cache from "../service/cache";


    export default {
        name: 'Settings',
        data: function () {
            return {}
        },
        components: {
        },
        mounted: function () {
        },
        computed: {
        }
    }
</script>